import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, MenuItem, TextField, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {firebase} from "../firebase/clientApp";
import {Edit} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../config";
import {CustomAlert} from "./shared/CustomAlert";

export const DisplayName = ({userData, setUserData}) => {
    const {id} = useParams()
    const [state, setState] = useState({displayName: '', teamName: '', customField: ''})
    const [error, setError] = useState(false)
    const [setting, setSetting] = useState(false)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (userData) {
            setState(userData)
        }
    }, [userData])

    const handleSetDisplayName = (event) => {
        setState({...state, displayName: event.target.value})
    }

    const handleSetCustomField = (event) => {
        setState({...state, customField: event.target.value})
    }

    const handleSetTeam = (event) => {
        setState({...state, teamName: event.target.value})
    }

    const submitDisplayName = async (event) => {
        event.preventDefault()
        setSetting(true)
        let uid = firebase.auth().currentUser.uid
        await axios.post(`${API_URL}/events/${id}/users`, {user: uid, displayName: state.displayName, teamName: state?.teamName ?? "", customField: state.customField ?? ''})
            .then(res => {
                    setUserData({displayName: state.displayName ?? '', teamName: state.teamName ?? '', customField: state.customField ?? ''})
                    setError("")
                    setEditing(false)
            })
            .catch((err) => {
                console.log("NickName già preso!!!", err?.response?.data?.message ?? "There was an error")
                setError(err?.response?.data?.message ?? "There was an error")
            })
            .finally(() => {
                setSetting(false)
            })
    }

    return (
        <Box sx={{padding:'16px'}}>
            {
                (editing || userData.displayName==='' || !userData.displayName) &&
                    <Box>
                        {/*<Typography textAlign={'center'} fontWeight={'normal'} fontSize={20} sx={{pb: 2}}>
                            Inserisci i dati ed effettua l'accesso
                        </Typography>*/}
                        <form onSubmit={submitDisplayName}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{style: {fontSize: '1.2rem'}}}
                                placeholder="Nome e Cognome"
                                id="displayName"
                                value={state.displayName}
                                onChange={handleSetDisplayName}/>
                            <TextField
                                fullWidth
                                required
                                inputProps={{style: {fontSize: '1.2rem'}}}
                                placeholder="Email"
                                id="customField"
                                value={state.customField}
                                sx={{marginTop:'2vh'}}
                                onChange={handleSetCustomField}/>
                            <Box pt={2}>
                                {setting ? <CircularProgress/> :
                                    <Button variant={'wordcloud'} type={'submit'}
                                            disabled={
                                                (editing && userData.displayName === state.displayName) || (!state.displayName)
                                            }>
                                        Accedi
                                    </Button>}
                                {editing && <Button sx={{color: 'white'}} onClick={() => setEditing(false)}>
                                    Annulla
                                </Button>}
                                <CustomAlert open={!!error} message={error} setOpen={setError} severity={'error'}/>
                            </Box>
                        </form>
                    </Box>
                    /*:
                    <Box>
                        <Typography textAlign={'center'} fontSize={18} sx={{pb: 2}}>
                            Nome e cognome:
                        </Typography>
                        <Typography textAlign={'center'} fontSize={20} sx={{pb:1}}>
                            {userData.displayName}
                        </Typography>
                        <Typography textAlign={'center'} fontSize={20} sx={{pb:1}}>
                        {userData.teamName ? `TEAM: #${userData.teamName}` : ''}
                        </Typography>
                        <Button startIcon={<Edit/>} size={'small'} onClick={() => setEditing(!editing)}>
                            modifica
                        </Button>
                    </Box>*/
            }

        </Box>
    )
}
