import  create from 'zustand';

const useUserStore = create((set) => ({
    userData: { displayName: '', teamName: '', customField: '' }, // Stato iniziale
    setUserData: (newData) => set((state) => ({
        userData: { ...state.userData, ...newData }, // Aggiorna i campi in modo dinamico
    })),
}));

export default useUserStore;
